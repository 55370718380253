import request from '@/utils/request';
import getConfig from 'next/config';
import queryString from 'query-string';
import { saveAs } from 'file-saver';
import { onLogOut } from '@/store/actions/session.actions';
import pluralize from 'pluralize';

import { XCSRFHeader } from '@/utils/auth';
const { publicRuntimeConfig } = getConfig();

import { LOG_OUT } from '@/store/actions/session.actions';
import { getLoginStack } from '@/utils/storage';

export const USERS = 'USERS';
export const USER = 'USER';
export const CREATE_USERS = 'CREATE_USERS';
export const CATEGORY = 'CATEGORY';
export const PRODUCTS = 'PRODUCTS';
export const PRODUCT = 'PRODUCT';
export const PAYMENT_INFOS = 'PAYMENT_INFOS';
export const CORPORATIONS = 'CORPORATIONS';
export const CORPORATION = 'CORPORATION';
export const ORDER_CSV = 'ORDER_CSV';
export const ORDER = 'ORDER';
export const BROADCAST_EMAILS = 'BROADCAST_EMAILS';
export const BROADCAST_EMAIL = 'BROADCAST_EMAIL';
export const GROUPS = 'GROUPS';
export const GROUP = 'GROUP';
export const POOL = 'POOL';
export const POOLS = 'POOLS';
export const SIGNUPS = 'SIGNUPS';
export const BUSINESS_UNITS = 'BUSINESS_UNITS';
export const BUSINESS_UNIT = 'BUSINESS_UNIT';
export const ACTIVE_FEATURE_FLAG = 'ACTIVE_FEATURE_FLAG';
export const CAMPAIGN_TRIGGERS = 'CAMPAIGN_TRIGGERS';
export const CAMPAIGN_TRIGGER = 'CAMPAIGN_TRIGGER';
export const FEATURE_FLAGS = 'FEATURE_FLAGS';
export const LOGIN_TYPES = {
  LOGIN_AS: 'login_as',
  IMPERSONATE: 'impersonate'
};
export const DEFAULT_USER_LIMIT = 20;

export const fetchAdminUsers =
  ({ page, limit, q, order, order_field, impersonators, user_id, only_rm }) =>
  async dispatch => {
    const response = await request({
      path: `/v1/admin/users`,
      query: { page, limit, q, order, order_field, impersonators, user_id, only_rm }
    });
    dispatch({
      payload: response,
      type: USERS
    });
    return response;
  };

export const fetchUsers = query => async () =>
  await request({
    path: `/v1/admin/users`,
    query
  });

export const fetchAdminUser = id => async dispatch => {
  const response = await request({
    path: `/v1/admin/users/${id}`
  });
  dispatch({
    payload: response,
    type: USER
  });
};

export const fetchAdminUserRoles = id => async () =>
  await request({
    path: `/v1/admin/users/${id}/roles`
  });

export const updateAdminUserRoles = (id, body) => async () =>
  await request({
    path: `/v1/admin/users/${id}/update_roles`,
    method: 'PATCH',
    body
  });

export const createAdminUser = body => async () =>
  await request({
    path: `/v1/admin/users`,
    method: 'POST',
    body
  });

export const updateAdminUser = (id, body) => async () =>
  await request({
    path: `/v1/admin/users/${id}`,
    method: 'PATCH',
    body
  });

export const quickUpdateAdminUser = (id, body) => async () =>
  await request({
    path: `/v1/admin/users/${id}/quick_update`,
    method: 'PATCH',
    body
  });

export const loginAsUser =
  (id, { login_type, asAdmin, asRM, asImpersonated }) =>
  async dispatch => {
    await request({
      path: `/v1/admin/users/${id}/login`,
      method: 'POST',
      body: { login_type }
    });
    const stack = getLoginStack();
    if (asRM) {
      stack.push('impersonating_as_rm');
    } else if (asAdmin) {
      stack.push('logged_as_admin');
    } else if (asImpersonated) {
      stack.push('impersonating');
    }
    window.location = '/';
    await dispatch({ type: LOG_OUT });
  };

export const logBack = as => async dispatch => {
  getLoginStack().pop();
  await dispatch(onLogOut({ persistLoginStack: true }));
  await request({
    path: `/v1/sessions/${as}`,
    method: 'POST'
  });
  window.location = '/';
};

export const createAdminPaidMembership = (id, body) => async () =>
  await request({
    path: `/v1/admin/users/${id}/paid_membership`,
    method: 'POST',
    body
  });

export const fetchOwners = query => async () =>
  await request({
    path: `/v1/admin/users/owners`,
    query
  });

export const fetchUsersPools = q => async () =>
  await request({
    path: `/v1/pools`,
    query: { q }
  });

export const deleteAdminUser = id => async () =>
  await request({
    path: `/v1/admin/users/${id}`,
    method: 'DELETE'
  });

export const fetchCarriers = q => async () =>
  await request({
    path: `/v1/admin/carriers`,
    query: { q }
  });

export const createCarrier = body => async () =>
  await request({
    path: `/v1/admin/carriers`,
    method: 'POST',
    body
  });

export const updateCarrier = body => async () =>
  await request({
    path: `/v1/admin/carriers/${body.id}`,
    method: 'PATCH',
    body
  });

export const deleteCarrier = id => async () =>
  await request({
    path: `/v1/admin/carriers/${id}`,
    method: 'DELETE'
  });

export const fetchStatuses = q => async () =>
  await request({
    path: `/v1/admin/statuses`,
    query: { q }
  });

export const createStatus = body => async () =>
  await request({
    path: `/v1/admin/statuses`,
    method: 'POST',
    body
  });

export const updateStatus = body => async () =>
  await request({
    path: `/v1/admin/statuses/${body.id}`,
    method: 'PATCH',
    body
  });

export const deleteStatus = id => async () =>
  await request({
    path: `/v1/admin/statuses/${id}`,
    method: 'DELETE'
  });

export const fetchSources = q => async () =>
  await request({
    path: `/v1/admin/sources`,
    query: { q }
  });

export const createSource = body => async () =>
  await request({
    path: `/v1/admin/sources`,
    method: 'POST',
    body
  });

export const updateSource = body => async () =>
  await request({
    path: `/v1/admin/sources/${body.id}`,
    method: 'PATCH',
    body
  });

export const deleteSource = id => async () =>
  await request({
    path: `/v1/admin/sources/${id}`,
    method: 'DELETE'
  });

export const fetchCategories =
  ({ q }) =>
  async () =>
    await request({
      path: `/v1/admin/categories`,
      query: { q }
    });

export const fetchCategory = id => async dispatch => {
  const response = await request({
    path: `/v1/admin/categories/${id}`
  });
  dispatch({
    payload: response,
    type: CATEGORY
  });
};

export const changeCategory = (id, title, description) => async () =>
  await request({
    path: `/v1/admin/categories/${id}`,
    method: 'PATCH',
    body: { title, description }
  });

export const createCategory = (title, description) => async () =>
  await request({
    path: `/v1/admin/categories`,
    method: 'POST',
    body: { title: title, description: description }
  });

export const deleteCategory = id => async () =>
  await request({
    path: `/v1/admin/categories/${id}`,
    method: 'DELETE'
  });

export const fetchProducts =
  ({ q, page, limit, all, category }) =>
  async dispatch => {
    const response = await request({
      path: `/v1/admin/products`,
      query: { q, page, limit, all, category }
    });
    dispatch({
      payload: response,
      type: PRODUCTS
    });
  };

export const fetchProduct = id => async dispatch => {
  const response = await request({
    path: `/v1/admin/products/${id}`
  });
  dispatch({
    payload: response,
    type: PRODUCT
  });
};

export const editProduct =
  ({ id, name, description, category_id, corporation_id, price, months_count, active, image }) =>
  async () => {
    const formDataBody = new FormData();
    formDataBody.append('name', name);
    formDataBody.append('description', description);
    formDataBody.append('category_id', category_id);
    formDataBody.append('corporation_id', corporation_id);
    formDataBody.append('price', price);
    months_count && formDataBody.append('months_count', months_count);
    formDataBody.append('active', active);
    image.name && formDataBody.append('image', image);

    await request({
      path: `/v1/admin/products/${id}`,
      method: 'PATCH',
      formData: true,
      formDataBody
    });
  };

export const createProduct =
  ({ name, description, category_id, corporation_id, price, months_count, active, image }) =>
  async () => {
    const formDataBody = new FormData();
    formDataBody.append('name', name);
    formDataBody.append('description', description);
    formDataBody.append('category_id', category_id);
    formDataBody.append('corporation_id', corporation_id);
    formDataBody.append('price', price);
    months_count && formDataBody.append('months_count', months_count);
    formDataBody.append('active', active);
    formDataBody.append('image', image);

    await request({
      path: `/v1/admin/products`,
      method: 'POST',
      formData: true,
      formDataBody
    });
  };

export const deleteProduct = id => async () =>
  await request({
    path: `/v1/admin/products/${id}`,
    method: 'DELETE'
  });

export const fetchPaymentInfos =
  ({ user_id, q, all_tabs, tab, all_statuses, status_filter, search_fields, order_field, order, limit, page }) =>
  async dispatch => {
    const response = await request({
      path: `/v1/admin/order_management`,
      query: {
        user_id,
        q,
        all_tabs,
        tab,
        all_statuses,
        status_filter,
        search_fields,
        order_field,
        order,
        limit,
        page
      }
    });
    dispatch({
      payload: response,
      type: PAYMENT_INFOS
    });
  };

export const fetchCorporations =
  ({ q, type, business_unit_ids = [], company_id = '', for_campaign_triggers }) =>
  async dispatch => {
    const response = await request({
      path: `/v1/admin/corporations`,
      query: { q, type, business_unit_ids, company_id, for_campaign_triggers }
    });
    dispatch({
      payload: response,
      type: CORPORATIONS
    });
  };

export const createCorporation =
  (type, name, description, domain_name, color_1, color_2, color_3, files, logo, business_unit_id) => async () => {
    const formDataBody = new FormData();
    formDataBody.append('type', type);
    formDataBody.append('name', name);
    formDataBody.append('description', description || '');
    formDataBody.append('domain_name', domain_name);
    formDataBody.append('color_1', color_1);
    formDataBody.append('color_2', color_2);
    formDataBody.append('color_3', color_3);
    files &&
      files.map((file, index) => {
        file.id && formDataBody.append(`files[][${index}][id]`, file.id);
        file.file_title && formDataBody.append(`files[][${index}][file_title]`, file.file_title);
        file.preview_image &&
          file.preview_image.name &&
          formDataBody.append(`files[][${index}][preview_image]`, file.preview_image);
        file.preview_image &&
          file.preview_image.url &&
          formDataBody.append(`files[][${index}][remote_preview_image_url]`, file.preview_image.url);
        file.file && file.file.lastModified && formDataBody.append(`files[][${index}][file]`, file.file);
        file.file_link && formDataBody.append(`files[][${index}][file_link]`, file.file_link);
        file.delete && formDataBody.append(`files[][${index}][delete]`, file.delete);
      });
    formDataBody.append('logo', logo);
    formDataBody.append('business_unit_id', business_unit_id || null);

    await request({
      path: `/v1/admin/corporations`,
      method: 'POST',
      formData: true,
      formDataBody
    });
  };

export const fetchCorporation = (id, type) => async dispatch => {
  const response = await request({
    path: `/v1/admin/corporations/${id}`,
    query: { type }
  });
  dispatch({
    payload: response,
    type: CORPORATION
  });
};

export const updateCorporation =
  (
    id,
    type,
    name,
    description,
    domain_name,
    color_1,
    color_2,
    color_3,
    files,
    logo,
    business_unit_id,
    faqs,
    latest_news
  ) =>
  async () => {
    const formDataBody = new FormData();
    formDataBody.append('type', type);
    formDataBody.append('name', name);
    formDataBody.append('description', description);
    formDataBody.append('domain_name', domain_name);
    formDataBody.append('color_1', color_1);
    formDataBody.append('color_2', color_2);
    formDataBody.append('color_3', color_3);

    formDataBody.append('faqs', JSON.stringify(faqs ? faqs : []));
    formDataBody.append('latest_news', JSON.stringify(latest_news ? latest_news : []));

    files &&
      files.map((file, index) => {
        file.id && formDataBody.append(`files[][${index}][id]`, file.id);
        file.file_title && formDataBody.append(`files[][${index}][file_title]`, file.file_title);
        file.preview_image &&
          file.preview_image.name &&
          formDataBody.append(`files[][${index}][preview_image]`, file.preview_image);
        file.file && file.file.lastModified && formDataBody.append(`files[][${index}][file]`, file.file);
        file.file_link && formDataBody.append(`files[][${index}][file_link]`, file.file_link);
        file.delete && formDataBody.append(`files[][${index}][delete]`, file.delete);
      });
    logo.name && formDataBody.append('logo', logo);
    formDataBody.append('business_unit_id', business_unit_id || null);

    await request({
      path: `/v1/admin/corporations/${id}`,
      method: 'PATCH',
      formData: true,
      formDataBody
    });
  };

export const deleteCorporation = id => async () =>
  await request({
    path: `/v1/admin/corporations/${id}`,
    method: 'DELETE'
  });

export const exportOrderCSV =
  ({ all, ids, order_type }) =>
  async dispatch => {
    const url = `${
      publicRuntimeConfig.API_URL
    }/v1/admin/order_management/export.csv?&all=${all}&${queryString.stringify(
      { ids, order_type },
      { arrayFormat: 'bracket' }
    )}`;

    await fetch(url, {
      headers: Object.assign({ 'Content-Type': 'application/json' }, XCSRFHeader()),
      credentials: 'include',
      responseType: 'blob'
    }).then(response =>
      dispatch({
        payload: response.blob().then(blob => saveAs(blob, `${pluralize('order', ids.length)}.csv`)),
        type: ORDER_CSV
      })
    );
  };

export const fetchOrder = id => async dispatch => {
  const response = await request({
    path: `/v1/admin/order_management/${id}`
  });
  dispatch({
    payload: response,
    type: ORDER
  });
};

export const manageOrder =
  ({ id, amount }) =>
  async () =>
    await request({
      path: `/v1/admin/order_management/${id}/refund`,
      method: 'POST',
      body: { amount }
    });

export const updateOrder =
  ({ id, admin_status }) =>
  async () =>
    await request({
      path: `/v1/admin/order_management/${id}`,
      method: 'PATCH',
      body: { admin_status }
    });

export const fetchBroadcastEmails =
  ({ q, order, page, limit, all }) =>
  async dispatch => {
    const response = await request({
      path: `/v1/admin/emails`,
      query: { q, order, page, limit, all }
    });
    dispatch({
      payload: response,
      type: BROADCAST_EMAILS
    });
    return response;
  };

export const fetchBroadcastEmail =
  ({ id }) =>
  async dispatch => {
    const response = await request({
      path: `/v1/admin/emails/${id}`
    });
    dispatch({
      payload: response,
      type: BROADCAST_EMAIL
    });
  };

export const createBroadcastEmail = body => async () =>
  await request({
    path: `/v1/admin/emails`,
    method: 'POST',
    body
  });

export const updateBroadcastEmail = (id, body) => async () =>
  await request({
    path: `/v1/admin/emails/${id}`,
    method: 'PATCH',
    body
  });

export const deleteBroadcastEmail = id => async () =>
  await request({
    path: `/v1/admin/emails/${id}`,
    method: 'DELETE'
  });

export const fetchActiveFeatureFlag = () => async dispatch => {
  const response = await request({
    path: `/v1/admin/feature_flags`
  });
  dispatch({
    payload: response,
    type: ACTIVE_FEATURE_FLAG
  });
};

export const fetchFeatureFlags = () => async dispatch => {
  const response = await request({
    path: `/v1/admin/features`
  });
  dispatch({
    payload: response,
    type: FEATURE_FLAGS
  });
};

export const updateFeatureFlag = body => async () => {
  await request({
    path: `/v1/admin/feature_flags`,
    method: 'PUT',
    body
  });
};

export const quietJobs = () => async () => {
  await request({
    path: `/v1/admin/quiet_jobs`,
    method: 'POST'
  });
};

export const sendHelpQuestion = body => async () =>
  await request({
    path: `/v1/helps`,
    method: 'POST',
    body
  });

export const createGroup = body => async () =>
  await request({
    path: `/v1/groups`,
    method: 'POST',
    body
  });

export const fetchGroups = query => async dispatch => {
  const response = await request({
    path: `/v1/groups`,
    query
  });
  dispatch({
    payload: response,
    type: GROUPS
  });
};

export const fetchGroup = id => async dispatch => {
  const response = await request({
    path: `/v1/groups/${id}`
  });
  dispatch({
    payload: response,
    type: GROUP
  });
};

export const updateGroup = (id, name) => async () =>
  await request({
    path: `/v1/groups/${id}`,
    method: 'PATCH',
    body: { name }
  });

export const deleteGroup = id => async () =>
  await request({
    path: `/v1/groups/${id}`,
    method: 'DELETE'
  });

export const fetchPool = id => async dispatch => {
  const response = await request({
    path: `/v1/pools/${id}`
  });
  dispatch({
    payload: response,
    type: POOL
  });
};

export const createPool = body => async () =>
  await request({
    path: `/v1/pools`,
    method: 'POST',
    body
  });

export const fetchPools = query => async dispatch => {
  const response = await request({
    path: `/v1/pools`,
    query
  });
  dispatch({
    payload: response,
    type: POOLS
  });
};

export const updatePool = (id, body) => async () =>
  await request({
    path: `/v1/pools/${id}`,
    method: 'PATCH',
    body
  });

export const deletePool = id => async () =>
  await request({
    path: `/v1/pools/${id}`,
    method: 'DELETE'
  });

export const fetchSignups = query => async dispatch => {
  const response = await request({
    path: `/v1/admin/signups`,
    query
  });
  dispatch({
    payload: response,
    type: SIGNUPS
  });
};

export const fetchCampaignTriggers =
  ({ q, order, page, limit, all, trigger_type, recurrence }) =>
  async dispatch => {
    const response = await request({
      path: `/v1/admin/campaign_triggers`,
      query: { q, order, page, limit, all, trigger_type, recurrence }
    });
    dispatch({
      payload: response,
      type: CAMPAIGN_TRIGGERS
    });
    return response;
  };

export const fetchCampaignTrigger =
  ({ id }) =>
  async dispatch => {
    const response = await request({
      path: `/v1/admin/campaign_triggers/${id}`
    });
    dispatch({
      payload: response,
      type: CAMPAIGN_TRIGGER
    });
  };

export const createCampaignTrigger = body => async dispatch => {
  await request({
    path: `/v1/admin/campaign_triggers`,
    method: 'POST',
    body
  });
  dispatch({
    payload: { data: {} },
    type: CAMPAIGN_TRIGGER
  });
};

export const updateCampaignTrigger = (id, body) => async dispatch => {
  await request({
    path: `/v1/admin/campaign_triggers/${id}`,
    method: 'PATCH',
    body
  });
  dispatch({
    payload: { data: {} },
    type: CAMPAIGN_TRIGGER
  });
};

export const deleteCampaignTrigger = id => async () =>
  await request({
    path: `/v1/admin/campaign_triggers/${id}`,
    method: 'DELETE'
  });
