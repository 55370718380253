import React, { Component } from 'react';
import Router from 'next/router';
import { withErrorBoundary } from '@/utils/errors';
import { setCookie, parseCookies, destroyCookie } from 'nookies';
import getConfig from 'next/config';
import isObject from 'isobject';
import { getDisplayName, isAbortError } from '@/utils/helpers';
import { getLoginStack } from '@/utils/storage';

const { publicRuntimeConfig } = getConfig();

const auth = ctx => {
  const { token } = parseCookies(ctx);
  const info = ctx.store.getState()?.currentAccount?.info;
  const billingInfoError =
    !ctx.pathname.includes('/account/profile') &&
    !ctx.pathname.includes('billing') &&
    isObject(info) &&
    !info['paid_user?'];
  const changePasswordError = !ctx.pathname.includes('/account/profile') && isObject(info) && info['change_password?'];

  const currentUser = ctx.store.getState()?.currentUser;
  const twoFaRequiredError =
    !(
      ctx.pathname.includes('billing/plan') ||
      ctx.pathname.includes('billing/update') ||
      ctx.pathname.includes('/account/profile')
    ) &&
    isObject(currentUser) &&
    currentUser['two_fa_is_required?'] &&
    !currentUser['has_2fa_enabled?'] &&
    !(getLoginStack()
      ? getLoginStack().peek() === 'impersonating_as_rm' ||
        getLoginStack().peek() === 'impersonating' ||
        getLoginStack().peek() === 'logged_as_admin'
      : true);

  if (ctx.req) {
    if (!token) {
      ctx.res.statusCode = 302;
      ctx.res.setHeader('Location', '/login');
      setRedirectPath(ctx);
      return;
    }
    if (!currentUser.updated_tos) {
      ctx.res.statusCode = 302;
      return;
    }
    if (changePasswordError) {
      ctx.res.statusCode = 302;
      ctx.res.setHeader('Location', '/account/profile?tab=password');
      return;
    }
    if (twoFaRequiredError) {
      ctx.res.statusCode = 302;
      ctx.res.setHeader('Location', '/account/profile?tab=two_factor');
      return;
    }
    if (billingInfoError) {
      ctx.res.statusCode = 302;
      ctx.res.setHeader('Location', '/billing');
      return;
    }
  }
  if (!currentUser.updated_tos) {
    return;
  }
  if (changePasswordError) {
    return Router.push('/account/profile?tab=password');
  }
  if (twoFaRequiredError) {
    return Router.push('/account/profile?tab=two_factor');
  }
  if (billingInfoError) {
    return Router.push('/billing');
  }
};

export const XCSRFHeader = () => {
  return { 'X-CSRF-Token': parseCookies()?.['X-CSRF-Token'] || '' };
};

export const handleUnauthorizedError = async ({ err, ctx }) => {
  if (isAbortError(err)) return null;
  if (err?.response?.status === 401) {
    destroyCookie(ctx, 'token', {
      domain: publicRuntimeConfig.APP_ENV !== 'development' && `.${publicRuntimeConfig.WEBPAGE_HOST}`
    });
    setRedirectPath(ctx);
    if (ctx.res) {
      ctx.res.statusCode = 302;
      ctx.res.setHeader('Location', '/login');
    } else {
      window.location = '/login';
    }
  } else {
    throw err;
  }
};

const setRedirectPath = ctx => {
  if (!['/contacts'].some(element => ctx.asPath.includes(element))) return;
  setCookie(ctx, 'redirect_path', ctx.asPath, {
    path: '/',
    domain: publicRuntimeConfig.APP_ENV !== 'development' && `.${publicRuntimeConfig.WEBPAGE_HOST}`
  });
};

export const withAuthSync = WrappedComponent => {
  const ComponentWithErrorBoundary = withErrorBoundary(WrappedComponent);
  return class extends Component {
    static displayName = `withAuthSync(${getDisplayName(WrappedComponent)})`;

    static async getInitialProps({ ctx, router }) {
      auth(ctx);

      const componentProps =
        ComponentWithErrorBoundary.getInitialProps &&
        (await ComponentWithErrorBoundary.getInitialProps({ ctx, router }));

      return { ...componentProps };
    }

    render() {
      return <ComponentWithErrorBoundary {...this.props} />;
    }
  };
};
