import request from '@/utils/request';

export const CONVERSATIONS = 'CONVERSATIONS';
export const MESSAGES = 'MESSAGES';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const SMS_PLANS = 'SMS_PLANS';
export const SMS_PLAN = 'SMS_PLAN';
export const SMS_SUBSCIPTIONS = 'SMS_SUBSCIPTIONS';
export const SMS_SUBSCIPTION = 'SMS_SUBSCIPTION';
export const SMS_SUBSCIPTION_COMPANY = 'SMS_SUBSCIPTION_COMPANY';
export const SMS_USAGE = 'SMS_USAGE';
export const SMS_LICENSES = 'SMS_LICENSES';
export const SMS_LICENSE = 'SMS_LICENSE';
export const SMS_ORDERS = 'SMS_ORDERS';
export const SMS_ORDER = 'SMS_ORDER';
export const SMS_ADDONS = 'SMS_ADDONS';
export const PHONE_VERIFICATIONS = 'PHONE_VERIFICATIONS';

export const updatePermission = body => async () =>
  await request({
    path: `/v1/sms/update_permission`,
    method: 'POST',
    body: body
  });

export const fetchConversations = () => async dispatch => {
  const response = await request({
    path: `/v1/sms/fetch_conversations`,
    method: 'POST'
  });
  dispatch({
    payload: response,
    type: CONVERSATIONS
  });
};

export const readConversation = body => async dispatch => {
  const response = await request({
    path: `/v1/sms/read_conversation`,
    method: 'POST',
    body: body
  });
  dispatch({
    payload: response,
    type: CONVERSATIONS
  });
};

export const fetchMessages = (phone_number, contact_id) => async dispatch => {
  const response = await request({
    path: `/v1/sms/fetch_messages`,
    method: 'POST',
    body: { phone_number, contact_id }
  });
  dispatch({
    payload: response,
    type: MESSAGES
  });
};

export const sendMessage = body => async () => {
  const formDataBody = new FormData();
  formDataBody.append('contact_id', body.contact_id);
  formDataBody.append('message_to', body.message_to);
  formDataBody.append('message_content', body.message_content);
  formDataBody.append('campaign_trigger_id', body.campaign_trigger_id);
  formDataBody.append('file', body.file);
  formDataBody.append('mms', body.mms);
  formDataBody.append('is_mss', body.is_mss);

  return await request({
    path: `/v1/sms/send_message`,
    method: 'POST',
    formData: true,
    formDataBody
  });
};

export const fetchSmsPlans = () => async dispatch => {
  const response = await request({
    path: `/v1/sms/sms_plans`
  });
  dispatch({ payload: response, type: SMS_PLANS });
};

export const fetchSmsAddons = () => async dispatch => {
  const response = await request({
    path: `/v1/sms/sms_plans_addons`
  });
  dispatch({ payload: response, type: SMS_ADDONS });
};

export const fetchSmsPlansEnterprise = body => async dispatch => {
  const response = await request({
    path: `/v1/sms/sms_plans_enterprise`
  });
  dispatch({ payload: response, type: SMS_PLANS });
};

export const fetchSmsPlansAdmin =
  ({ q, order, page, limit, all }) =>
  async dispatch => {
    const response = await request({
      path: `/v1/sms/sms_plans_admin`,
      query: { q, order, page, limit, all }
    });
    dispatch({ payload: response, type: SMS_PLANS });
  };

export const fetchSmsPlan =
  ({ id }) =>
  async dispatch => {
    const response = await request({
      path: `/v1/sms/sms_plans/${id}`
    });
    dispatch({
      payload: response,
      type: SMS_PLAN
    });
  };

export const createSmsPlan = body => async () =>
  await request({
    path: `/v1/sms/sms_plans`,
    method: 'POST',
    body
  });

export const updateSmsPlan = (id, body) => async () =>
  await request({
    path: `/v1/sms/sms_plans/${id}`,
    method: 'PATCH',
    body
  });

export const deleteSmsPlan = id => async () =>
  await request({
    path: `/v1/sms/sms_plans/${id}`,
    method: 'DELETE'
  });

export const fetchSmsSubscriptions =
  ({ q, order, order_field, page, limit, active, mine }) =>
  async dispatch => {
    const response = await request({
      path: `/v1/sms/sms_subscriptions`,
      method: 'GET',
      query: { q, order, order_field, page, limit, active, mine }
    });
    dispatch({
      payload: response,
      type: SMS_SUBSCIPTIONS
    });
  };

export const fetchSmsSubscription =
  ({ id }) =>
  async dispatch => {
    const response = await request({
      path: `/v1/sms/sms_subscriptions/${id}`
    });
    dispatch({
      payload: response,
      type: SMS_SUBSCIPTION
    });
  };

export const createSmsSubscription = body => async () =>
  await request({
    path: `/v1/sms/sms_subscriptions`,
    method: 'POST',
    body
  });

export const updateSmsSubscription = (id, body) => async () =>
  await request({
    path: `/v1/sms/sms_subscriptions/${id}`,
    method: 'PATCH',
    body
  });

export const cancelSmsSubscription = (id, type) => async () =>
  await request({
    path: `/v1/sms/sms_subscriptions/${id}/cancel`,
    method: 'POST',
    body: { type }
  });

export const fetchSmsActiveSubscription = type => async dispatch => {
  const response = await request({
    path: `/v1/sms/sms_subscriptions_active`,
    query: { type }
  });
  dispatch({
    payload: response && response.data ? response : { data: {} },
    type: type && type === 'company' ? SMS_SUBSCIPTION_COMPANY : SMS_SUBSCIPTION
  });
};

export const fetchUsage = body => async dispatch => {
  const response = await request({
    path: `/v1/sms/sms_subscriptions_usage`
  });
  dispatch({
    payload: response,
    type: SMS_USAGE
  });
};

export const fetchSmsLicenses = body => async dispatch => {
  const response = await request({
    path: `/v1/sms/sms_licenses`
  });
  dispatch({
    payload: response,
    type: SMS_LICENSES
  });
};

export const updateSmsLicense = (id, body) => async () =>
  await request({
    path: `/v1/sms/sms_licenses/${id}`,
    method: 'PATCH',
    body
  });

export const orderSmsLicenses = body => async () =>
  await request({
    path: `/v1/sms/sms_licenses_order`,
    method: 'POST',
    body
  });

export const fetchSmsOrders = body => async dispatch => {
  const response = await request({
    path: `/v1/sms/sms_orders`
  });
  dispatch({
    payload: response,
    type: SMS_ORDERS
  });
};

export const fetchSmsOrder = id => async dispatch => {
  const response = await request({
    path: `/v1/sms/sms_orders/${id}`
  });
  dispatch({
    payload: response,
    type: SMS_ORDER
  });
};

export const mobileValidationPurchase = body => async () =>
  await request({
    path: `/v1/sms/mobile_validation_purchase`,
    method: 'POST',
    body
  });

export const mobileValidationSingle = body => async () =>
  await request({
    path: `/v1/sms/mobile_validation_single`,
    method: 'POST',
    body
  });

export const mobileValidationBulk = body => async () =>
  await request({
    path: `/v1/sms/mobile_validation_bulk`,
    method: 'POST',
    body
  });

export const fetchPhoneVerifications =
  ({ limit, q, page, order_field, order }) =>
  async dispatch => {
    const response = await request({
      path: `/v1/sms/phone_verifications`,
      query: { limit, order_field, order, q, page }
    });
    dispatch({
      payload: response,
      type: PHONE_VERIFICATIONS
    });
  };
